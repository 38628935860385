@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.activation-codes-admin {
  display: flex;
  align-items: center;
  flex-direction: column;

  .back-link {
    width: 80%;

    @media (min-width: $sm) {
      width: 440px;
    }
  }
}
