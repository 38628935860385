@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.add-comment {
  .text-field {
    width: 100%;
  }

  button {
    margin: 1em 0;
    min-width: 120px;
    width: 100%;

    @media (min-width: $sm) {
      width: inherit;
    }
  }
}
