@import '../../sass/colors';

.date-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  input {
    background-color: $white;
    border-radius: 4px;
    height: 19px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
}