@import './colors';

@mixin dauntless-header() {
  font-family: 'good-times-rg';
  font-size: 48px;
  color: $dauntless-blue;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 2px 6px;
  line-height: 1;
}

@mixin dauntless-subheader() {
  font-family: 'good-times-rg';
  font-size: 24px;
  font-weight: bold;
  color: $black;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 2px 6px;
  line-height: 1;
}
