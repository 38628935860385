@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.request-access-modal {
  display: flex;
  align-items: center;
  flex-direction: column;

  .request-access-dialog-title {
    display: flex;
    align-content: space-between;

    h3 {
      @include dauntless-subheader();
    }
  }
}
