@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.generate-activation-code {
  h3 {
    @include dauntless-subheader();
    margin: 0 0 16px;
    text-align: center;
    font-size: 16px;

    @media (min-width: 400px) {
      font-size: 20px;
    }

    @media (min-width: $sm) {
      font-size: 24px;
    }
  }

  .alert {
    margin-top: 16px;
  }

  .shadow-box {
    margin-bottom: 2em;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .submit-button {
    height: 36px;
    min-width: 100px;
    width: 100%;
    margin-top: 1em;

    @media (min-width: $sm) {
      float: right;
      width: initial;
    }
  }
}
