@import '../../sass/box-shadow';
@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.skeleton-card {
  width: 300px;

  &.MuiCard-root {
    box-shadow: $box-shadow;
  }

  .title {
    background-image: linear-gradient(90deg, $skeleton 0px, white 100px, $skeleton 200px);
    border-radius: 20px;
    animation: shine-title 2.0s infinite linear;
    height: 24px;
    width: 200px;
    margin: 0 0 16px 0;

    @keyframes shine-title {
      0% {
        background-position: -200px;
      }
    }
  }

  // .description {
  //   background-image: linear-gradient(90deg, $skeleton 0px, white 100px, $skeleton 268px);
  //   border-radius: 20px;
  //   animation: shine-description 1.6s infinite linear;
  //   height: 20px;
  //   width: 268px;

  //   @keyframes shine-description {
  //     0% {
  //       background-position: -268px;
  //     }
  //   }
  // }

  .skeleton-card-thumbnail {
    height: 0;
    padding-top: 56.25%; // 16:9
    background-color: $skeleton;
  }
}
