@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.watch-live-chat-video {
  .video-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;

    .back-link {
      margin: 0 10px;
    }

    h3.name {
      @include dauntless-subheader();
      margin: 24px 10px 24px;
    }

    .description {
      margin: 0 10px;
    }

    @media (min-width: $lg) {
      .back-link {
        margin: 0;
      }

      h3.name {
        margin: 24px 0 24px;
      }

      .description {
        margin: 0;
      }
    }
  }
}
