@import '../../sass/breakpoints';
@import '../../sass/headings';

.header {
  display: flex;
  flex-direction: column;
  width: 100%;

  .nav-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1em 1em 0 1em;

    &.with-logo {
      justify-content: space-between;
    }

    img {
      width: 150px;
    }

    .buttons-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      > * {
        margin: 0 1em;
      }
    }
  }

  h1 {
    @include dauntless-header();
    margin: 32px 0;
    text-align: center;
    font-size: 24px;

    @media (min-width: 400px) {
      font-size: 32px;
    }

    @media (min-width: $sm) {
      font-size: 48px;
    }
  }
}
