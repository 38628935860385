@import '../../sass/box-shadow';
@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.video-card {
  width: 300px;

  &.MuiCard-root {
    box-shadow: $box-shadow;
  }

  h5 {
    @include dauntless-subheader();
    margin: 0 0 16px 0;
  }

  .description {
    line-height: 20px;
  }

  .video-card-thumbnail {
    height: 0;
    padding-top: 56.25%; // 16:9
  }
}
