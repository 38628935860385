@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.account-settings {
  display: flex;
  align-items: center;
  flex-direction: column;

  .back-link {
    width: 80%;

    @media (min-width: $sm) {
      width: 440px;
    }
  }

  h3 {
    @include dauntless-subheader();
    margin: 0 0 16px;
    text-align: center;
    font-size: 16px;

    @media (min-width: 400px) {
      font-size: 20px;
    }

    @media (min-width: $sm) {
      font-size: 24px;
    }
  }

  .error-message {
    line-height: 1.25;
    margin-bottom: 16px;
    text-align: center;
  }

  .saved-badge {
    float: right;
  }

  .shadow-box {
    margin-bottom: 2em;
  }

  .submit-button {
    height: 36px;
    min-width: 100px;
    width: 100%;

    @media (min-width: $sm) {
      float: right;
      width: initial;
    }
  }
}
