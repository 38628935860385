@import './sass/colors';

html,
body {
  background-color: $background-color;
  width: 100%;
  height: 100%;

  line-height: 2;
  margin: 0;
  font-family: 'play-rg', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @supports (
    (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    background: url('/images/dauntless-watermark.png') center center no-repeat;
    background-attachment: fixed;
    background-size: 75%;

    .blur {
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      flex-grow: 1;
    }
  }
}

a {
  color: $dauntless-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.external[target="_blank"]::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0px 3px 0px 5px;
  }
}

.error-label {
  color: $error;
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

.strong {
  font-weight: bold;
}

.MuiLink-root {
  cursor: pointer;
}
