@import '../../sass/colors';

.text-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  input {
    background-color: $white;
    border-radius: 4px;
  }
}