@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.login {
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    @include dauntless-subheader();
    margin: 0 0 32px;
    text-align: center;
    font-size: 16px;

    @media (min-width: 400px) {
      font-size: 20px;
    }

    @media (min-width: $sm) {
      font-size: 24px;
    }
  }

  .cta-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $sm) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .submit-button {
      height: 36px;
      min-width: 100px;
    }

    .forgot-password-link {
      color: $gray;
      margin-top: 0.5em;
      text-align: center;

      @media (min-width: $sm) {
        margin-top: 0;
        text-align: left;
      }
    }
  }
}
