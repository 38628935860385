@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.nutrition {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;

  .nutrition-header, .meal-plan-image-wrapper, .button-wrapper, .live-chat, .nutrition-comments {
    width: 1000px;
    max-width: 90%;
  }

  h3 {
    @include dauntless-subheader();
    text-align: center;
  }

  .meal-plan-image-wrapper {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      align-self: flex-start;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    @media (min-width: $sm) {
      flex-direction: row;
      justify-content: space-evenly;
    }

    button {
      margin-bottom: 10px;
      min-width: 120px;
    }
  }
}
