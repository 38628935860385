@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.saved-badge {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: bold;

  svg {
    margin-right: 5px;
  }
}
