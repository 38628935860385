@import '../../sass/box-shadow';
@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.shadow-box {
  display: flex;
  flex-direction: column;
  width: 80%;

  @media (min-width: $sm) {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
    box-shadow: $box-shadow;
    padding: 30px;
    width: 380px;
  }
}
