@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.sign-up {
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    @include dauntless-subheader();
    margin: 0 0 32px;
    text-align: center;
    font-size: 16px;

    @media (min-width: 400px) {
      font-size: 20px;
    }

    @media (min-width: $sm) {
      font-size: 24px;
    }
  }

  .error-message {
    line-height: 1.25;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .cta-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $sm) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .submit-button {
      height: 36px;
      min-width: 100px;
    }

    .get-activation-code-link {
      font-weight: bold;
      margin-top: 0.5em;
      text-align: center;

      @media (min-width: $sm) {
        margin-top: 0;
        text-align: left;
      }
    }
  }
}