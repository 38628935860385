@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.reset-forgot-password {
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    @include dauntless-subheader();
    margin: 0 0 32px;
    text-align: center;
    font-size: 16px;

    @media (min-width: 400px) {
      font-size: 20px;
    }

    @media (min-width: $sm) {
      font-size: 24px;
    }
  }

  .error-message {
    line-height: 1.25;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .cta-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $sm) {
      flex-direction: row;
      justify-content: flex-end;
    }

    .submit-button {
      height: 36px;
      min-width: 100px;
      width: 100%;
      margin-bottom: 1rem;

      @media (min-width: $sm) {
        width: initial;
        margin-bottom: inherit;
      }
    }
  }
}