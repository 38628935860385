@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.video-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: $md;
  margin: 0 auto;

  > * {
    margin: 10px;
  }
}
