@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.menu-drawer {
  .MuiDrawer-paper {
    width: 300px;
  }

  .MuiListItemIcon-root {
    min-width: 2.5em;
  }

  .inset {
    margin-left: 2.5em;
  }

  h3 {
    @include dauntless-subheader();
    color: $dauntless-blue;
    text-align: center;
    font-size: 16px;

    @media (min-width: 400px) {
      font-size: 20px;
    }

    @media (min-width: $sm) {
      font-size: 24px;
    }
  }

  h6 {
    @include dauntless-subheader();
    text-align: center;
    font-size: 0.75em;
  }

  .nutrition-icon {
    color: $nutrition-green;
  }

  .video-icon {
    color: $dauntless-blue;
  }
}