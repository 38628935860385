@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.video-filters {
  margin: 1em 0;

  .filter {
    margin: 5px;
    min-width: 150px;
  }
}
