@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.feedback {
  position: sticky;
  bottom: 1em;
  right: 1em;
  float: right;

  $card-width: 275px;

  .feedback-card {
    width: $card-width;
    padding: 1em;

    h3 {
      margin: 0;
    }

    .feedback-textarea {
      width: $card-width - 6px;
      resize: none;
    }

    .close-link {
      color: $gray;
      margin-right: 1em;
    }
  }

  .feedback-fab {
    float: right;
    margin-top: 1em;

    .feedback-label {
      font-weight: bold;
      margin-right: 0.5em;
    }
  }
}
