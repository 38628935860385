@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.edit-account {
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    @include dauntless-subheader();
    margin: 0 0 16px;
    text-align: center;
    font-size: 16px;

    @media (min-width: 400px) {
      font-size: 20px;
    }

    @media (min-width: $sm) {
      font-size: 24px;
    }
  }

  form {
    width: 90%;
    max-width: 400px;

    .submit-button {
      float: right;
      min-width: 100px;
    }
  }
}
