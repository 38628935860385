.password-requirements-tooltip {
  margin: 1em 1em 0 1em;

  h3 {
    margin: 0;
  }

  ul {
    margin: 0 0 0 10px;
    padding: 10px 0;
    font-size: 1em;
  }
}