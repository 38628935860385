@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.live-chat {
  display: flex;
  flex-direction: column;

  @media (min-width: $sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .description {
    line-height: 1.5;
    text-align: center;
    margin: 10px 0;
  }

  button {
    background-color: $yellow;
    color: $black;
    font-weight: bold;
    min-width: 200px;
    margin: 10px 0;

    &:hover {
      background-color: $yellow;
    }
  }
}
