@import '../../sass/breakpoints';
@import '../../sass/colors';
@import '../../sass/headings';

.nutrition-comments {
  .add-comment, .comment {
    margin: 1em 0;
    padding: 1em;
  }
}
